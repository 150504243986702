/**
 * @todo refactoring
 */
import React from 'react'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'
import Layout from '../layouts/Index'
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import SEO from '../components/SEO'
import PreFooter from '../components/PreFooter'

if (typeof window !== 'undefined') {
  const getParams = () => {
    let params = window.location.href.split('?');
    return params.length === 2 ? `?${params[1]}` : '';
  };
  if (isMobile) {
    if (isIOS) {
      window.location.href = `https://itunes.apple.com/us/app/apple-store/id956879527?pt=117277742&ct=homepage&mt=8${getParams()}`;
    } else if(isAndroid) {
      window.location.href = `https://play.google.com/store/apps/details?id=com.wakatoon.app&referrer=utm_source%3Dfr.wakatoon.com%26utm_medium%3Dsite%26utm_campaign%3Dhomepage`;
    }
  }
}

class Download extends React.Component {

  renderContent = () => {
    // if (isMobile) {
    //   return <div></div>
    // }

    const {
      pageContext: { title },
      data: { seo, staticText }
    } = this.props;
    const staticPrismicTexts = staticText.edges[0].node.data.texts;
    const _seoData = seo.edges;
    const seoData = _seoData.length > 0 ? _seoData[0].node.data : [];
    const context = {...this.props.pageContext, isDownloadPage: true};
    return (
      <Layout 
        context={context} 
        pageTitle={seoData.download_page_title.text}
        _t={staticPrismicTexts}
      >
        <SEO 
          pageTitle={seoData.download_page_title.text} 
          pageDescription={seoData.download_page_description.text} 
          context={this.props.pageContext}
        />
        <div className="container page">
          <div className="breadcrumb">
            <Link to="/">Wakatoon</Link>&nbsp;/&nbsp;
            <Link to={ this.props.pageContext.breadcrumbParent.slug }>
              { this.props.pageContext.breadcrumbParent.label }
            </Link>&nbsp;/&nbsp;
            {title}
          </div>
        </div>
        <PreFooter locale={this.props.pageContext.locale} context={this.props.pageContext} isDownloadPage />
      </Layout>
    );
  }

  render() {
    return this.renderContent();
  };
};

export default Download;
export const pageQuery = graphql`
  query downloadPageSeo($locale: String!) {
    seo: allPrismicStaticpagesseo(filter: { lang: { eq: $locale} }) {
      edges {
        ...StaticPagesSeo
      }
    }
    staticText: allPrismicGlobalStatictexts(filter: { lang: { eq: $locale} }) {
      edges { ...StaticTexts }
    }
  }
`;